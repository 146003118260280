// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-header .Hero {
  height: 100vh;
  background-color: #013125;
  color: white;
}
@media (min-width: 900px) {
  .App-header .Hero {
    height: 80vh;
  }
}

.cottage-section-title {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
}

.card-cottages {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
}
.card-cottages .cottage-overview {
  height: 50vh;
  min-width: 100%;
}
@media (min-width: 1024px) {
  .card-cottages .cottage-overview {
    height: 100vh;
    min-width: 50%;
  }
}
@media (min-width: 1024px) {
  .card-cottages {
    flex-direction: row;
  }
}`, "",{"version":3,"sources":["webpack://./src/container/App/Home/style.scss"],"names":[],"mappings":"AAGI;EACI,aAAA;EACA,yBAAA;EACA,YAAA;AAFR;AAIQ;EALJ;IAMQ,YAAA;EADV;AACF;;AAKA;EACI,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;AAFJ;;AAMA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,eAAA;AAHJ;AAKI;EACI,YAAA;EACA,eAAA;AAHR;AAKQ;EAJJ;IAKQ,aAAA;IACA,cAAA;EAFV;AACF;AAKI;EAhBJ;IAiBQ,mBAAA;EAFN;AACF","sourcesContent":["@import '../../../styles/assets/variables';\n\n.App-header {\n    .Hero {\n        height: 100vh;\n        background-color: #013125;\n        color: white;\n\n        @media (min-width: 900px) {\n            height: 80vh;\n        }\n    }\n}\n\n.cottage-section-title {\n    width: 100%;\n    text-align: center;\n    text-transform: uppercase;\n    padding-top: 10px;\n}\n\n\n.card-cottages {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    flex-wrap: wrap;\n\n    .cottage-overview {\n        height: 50vh;\n        min-width: 100%;\n    \n        @media (min-width: 1024px) {\n            height: 100vh;\n            min-width: 50%;\n        }\n    }\n\n    @media (min-width: 1024px) {\n        flex-direction: row;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
